import { combineReducers } from 'redux'

import authReducer from './authReducer'
import errorReducer from './errorReducer'
import toastReducer from './toastReducer'
import talentsReducer from './talentsReducer'
import modalReducer from './modalReducer'
import jobPostingReducer from './jobPostingReducer'
import listsReducer from './listsReducer'
import recruiterReducer from './requisitionReducer'
import clientReducer from './clientsReducer'
import skillsReducer from './SkillReducer'
import jobListingReducer from './jobListingReducer'
import usersReducer from './usersReducer'
import emailTemplatesReducer from './emailTemplatesReducer'
import dashboardReducer from './dashboardReducer'

const reducers = combineReducers({
  auth: authReducer,
  clients: clientReducer,
  error: errorReducer,
  toast: toastReducer,
  talents: talentsReducer,
  modal: modalReducer,
  jobListing: jobListingReducer,
  jobPosting: jobPostingReducer,
  lists: listsReducer,
  requisitions: recruiterReducer,
  skills: skillsReducer,
  users: usersReducer,
  emailTemplates: emailTemplatesReducer,
  dashboard: dashboardReducer,
})

export default reducers
