import {
  FETCH_TALENTS_SUCCESS,
  FETCH_TALENT_SUCCESS,
  FETCH_TALENT_ERROR,
  FETCH_TALENTS_ERROR,
  FETCH_TALENT_LOADING,
  FETCH_TALENTS_LOADING,
  SORT_TALENTS,
  SELECT_TALENT,
  FETCH_PROFESSIONAL_HEADLINE_SUCCESS,
  FETCH_SKILLS_SUCCESS,
  SET_PROFESSIONAL_HEADLINES_FILTER,
  FETCH_TALENT_BY_FILTER_SUCCESS,
  SET_SKILL_FILTER,
  SET_FIRST_FILTER,
  SET_SECOND_FILTER,
  SET_LEVEL_OF_EDUCATION_FILTER,
  SET_COUNTRY_FILTER,
  SET_IMAGE_FILTER,
  SET_FILTER_BY_YEAR,
  FETCH_PERSONAL_SKILLS_SUCCESS,
  FETCH_PERSONAL_SKILLS_LOADING,
  FETCH_PERSONAL_SKILLS_ERROR,
  SET_FILTER_QUERY_TERM,
  SET_MORE_JOB_TITLE,
  FETCH_POPULAR_PROFESSIONAL_HEADLINES_SUCCESS,
  ADD_TALENT_CV,
  GET_TALENT_BY_ID,
  UPDATE_TALENTS,
  TOGGLE_IS_PROFESSIONAL,
  TOGGLE_IS_SKILL,
  TOGGLE_IS_EXPERIENCE,
  UPDATE_PAGINATION,
  SET_GLOBAL_SEARCH_TERM,
  SET_SELECTED_HARDWARE,
  SET_SELECTED_PING,
  SET_SELECTED_DOWNLOADMBPS,
  SET_SELECTED_UPLOADMBPS,
  SET_ALL_OF_THESE_WORDS,
  SET_COMPANY_NAME,
  SET_SOFT_SKILLS,
  SET_OS,
  SET_PROCESSOR,
  SET_RAM,
  INCREASE_ACTIVE_FILTER,
  TOGGLE_SIDE_FILTERS,
  SET_BULK_ACTION,
  SET_BULK_ITEMS,
} from '../actions/types'

const initialState = {
  talents: [],
  pagination: {},
  talent: {},
  selectedTalentId: '',
  professionalHeadlines: [],
  popularHeadlines: [],
  professionalHeadlinesFilters: [],
  skillsFilters: [],
  skills: [],
  personalSkills: [],
  country: null,
  firstFilter: '',
  hasPhoto: false,
  secondFilter: null,
  filterByYears: [],
  isSelected: false,
  isLoading: false,
  levelOfEducation: '',
  queryTerm: '',
  jobTitle: '',
  uploadedCv: [],
  isProfessionalHeadline: false,
  isSkils: false,
  isYearsOfExperience: false,
  globalSearchTerm: '',
  selectedHardware: '',
  selectedPing: '',
  downloadMBPS: '',
  uploadMBPS: '',
  allOfTheseWords: '',
  companyName: '',
  selectedSoftSkills: [],
  os: [],
  processor: [],
  ram: [],
  sortOrder: 'DESC',
  showSideFilters: true,
  showTalentPopout: false,
  activeFilters: {
    findProfile: 0,
    workExperience: 0,
    skills: 0,
    education: 0,
    hardware: 0,
    location: 0,
  },
  bulkItems: {
    selectedAll: false,
    checkedItems: [],
    uncheckedItems: [],
    filters: {},
  },
  bulkAction: '',
}

const talentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TALENTS_SUCCESS:
    case FETCH_TALENT_BY_FILTER_SUCCESS:
      return {
        ...state,
        talents: action.payload.talents,
        pagination: action.payload.pagination,
        isLoading: false,
      }

    case UPDATE_TALENTS:
      return {
        ...state,
        talents: action.payload,
      }

    case UPDATE_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      }

    case FETCH_TALENT_SUCCESS:
      return {
        ...state,
        talent: action.payload,
        isLoading: false,
      }

    case ADD_TALENT_CV:
      return {
        ...state,
        uploadedCv: action.payload,
      }

    case FETCH_TALENT_ERROR:
    case FETCH_TALENTS_ERROR:
    case FETCH_PERSONAL_SKILLS_ERROR:
      return {
        ...state,
        isLoading: false,
      }

    case FETCH_PROFESSIONAL_HEADLINE_SUCCESS:
      return {
        ...state,
        professionalHeadlines: action.payload,
        isLoading: false,
      }

    case FETCH_POPULAR_PROFESSIONAL_HEADLINES_SUCCESS:
      return {
        ...state,
        popularHeadlines: action.payload,
      }

    case SORT_TALENTS: {
      return {
        ...state,
        sortOrder: action.payload,
      }
    }

    case SET_GLOBAL_SEARCH_TERM: {
      return {
        ...state,
        globalSearchTerm: action.payload,
      }
    }

    case FETCH_SKILLS_SUCCESS:
      return {
        ...state,
        skills: action.payload,
        isLoading: false,
      }

    case FETCH_PERSONAL_SKILLS_SUCCESS:
      return {
        ...state,
        personalSkills: action.payload,
        isLoading: false,
      }

    case SET_SKILL_FILTER:
      return {
        ...state,
        skillsFilters: action.payload,
      }

    case FETCH_TALENT_LOADING:
    case FETCH_TALENTS_LOADING:
    case FETCH_PERSONAL_SKILLS_LOADING:
      return {
        ...state,
        isLoading: true,
      }

    case SELECT_TALENT:
      return {
        ...state,
        talent: action.payload,
      }

    case SET_PROFESSIONAL_HEADLINES_FILTER:
      return {
        ...state,
        professionalHeadlinesFilters: action.payload,
      }

    case SET_FIRST_FILTER:
      return {
        ...state,
        firstFilter: action.payload,
      }

    case SET_SECOND_FILTER:
      return {
        ...state,
        secondFilter: action.payload,
      }

    case SET_MORE_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.payload,
      }

    case SET_COUNTRY_FILTER:
      return {
        ...state,
        country: action.payload,
      }

    case SET_LEVEL_OF_EDUCATION_FILTER:
      return {
        ...state,
        levelOfEducation: action.payload,
      }

    case SET_IMAGE_FILTER:
      return {
        ...state,
        hasPhoto: action.payload,
      }

    case SET_FILTER_QUERY_TERM:
      return {
        ...state,
        queryTerm: action.payload,
      }

    case SET_FILTER_BY_YEAR:
      return {
        ...state,
        filterByYears: action.payload,
      }

    case GET_TALENT_BY_ID:
      return {
        ...state,
        selectedTalentId: action.payload,
      }

    case TOGGLE_IS_PROFESSIONAL:
      return {
        ...state,
        isProfessionalHeadline: action.payload,
      }

    case TOGGLE_IS_SKILL:
      return {
        ...state,
        isSkils: action.payload,
      }

    case TOGGLE_IS_EXPERIENCE:
      return {
        ...state,
        isYearsOfExperience: action.payload,
      }

    case SET_SELECTED_HARDWARE:
      return {
        ...state,
        selectedHardware: action.payload,
      }
    case SET_SELECTED_PING:
      return {
        ...state,
        selectedPing: action.payload,
      }

    case SET_SELECTED_DOWNLOADMBPS:
      return {
        ...state,
        downloadMBPS: action.payload,
      }
    case SET_SELECTED_UPLOADMBPS:
      return {
        ...state,
        uploadMBPS: action.payload,
      }

    case SET_ALL_OF_THESE_WORDS:
      return {
        ...state,
        allOfTheseWords: action.payload,
      }

    case SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload,
      }

    case SET_SOFT_SKILLS:
      return {
        ...state,
        selectedSoftSkills: action.payload,
      }
    case SET_OS:
      return {
        ...state,
        os: action.payload,
      }
    case SET_PROCESSOR:
      return {
        ...state,
        processor: action.payload,
      }
    case SET_RAM:
      return {
        ...state,
        ram: action.payload,
      }
    case INCREASE_ACTIVE_FILTER:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [action.payload.prop]: action.payload.amount,
        },
      }
    case TOGGLE_SIDE_FILTERS:
      return {
        ...state,
        showSideFilters: !state.showSideFilters,
      }
    case SET_BULK_ACTION:
      return {
        ...state,
        bulkAction: action.payload,
      }
    case SET_BULK_ITEMS:
      return {
        ...state,
        bulkItems: {
          ...state.bulkItems,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export default talentsReducer
