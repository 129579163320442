/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import { FiFilter, FiSearch } from 'react-icons/fi'

import Loader from '../../../../components/Loader'
import PageButtons from '../../../../components/PageButtons'
import axiosInstance from '../../../../services/config/AxiosIntance'
import JobList from './JobList'

import {
  getJobLists,
  isLoading,
} from '../../../../store/actions/jobListingActions'

const JobListingWrapper = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { jobListings, loading, pagination, selectedJob } = useSelector(
    (state) => state.jobListing
  )

  const [searchTerm, setSearchTerm] = useState('')
  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant

  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  function getJobListings(params) {
    dispatch(isLoading(true))
    axiosInstance
      .get('/jobs', {
        params: {
          ...params,
        },
      })
      .then((res) => {
        dispatch(isLoading(false))
        dispatch(getJobLists(res.data.data.jobs, res.data.data.pagination))

        if (res.data.data.jobs.length === 0) {
          navigate('/job-listing')
        }
      })
      .catch((err) => {
        console.log(
          'error',
          err.response.status,
          err.message,
          err.response.headers,
          err.response.data
        )
      })
  }

  useEffect(() => {
    const params = {
      page: 1,
    }

    if (role === 'recruiter' && !recruiterAssistant) {
      params.posted_by = userId
      params.status = 'active'
    } else if (role === 'poc') {
      params.companyDetails = JSON.stringify(companyDetails)
    }

    getJobListings(params)
  }, [selectedJob])

  function handleSearch(e) {
    e.preventDefault()

    const params = {
      searchJobBy: searchTerm,
    }

    if (role === 'recruiter' && !recruiterAssistant) {
      params.posted_by = userId
    } else if (role === 'poc') {
      params.companyDetails = JSON.stringify(companyDetails)
    }

    getJobListings(params)
  }

  function handleFetch(page) {
    const params = {
      page: page,
    }

    if (role === 'recruiter' && !recruiterAssistant) {
      params.posted_by = userId
    } else if (role === 'poc') {
      params.companyDetails = JSON.stringify(companyDetails)
    }

    getJobListings(params)
  }

  return (
    <Wrapper>
      <FilterWrapper>
        <form onSubmit={(e) => handleSearch(e)} className="input-wrapper">
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            placeholder="Search..."
          />

          <button type="submit">
            <FiSearch className="icon" />
          </button>
        </form>

        <div className="filterWrapper2">
          <FiFilter className="icon" />
        </div>
      </FilterWrapper>
      <StyledJobListingWrapper>
        {!loading ? (
          <>
            {jobListings.length > 0 ? (
              jobListings?.map(
                ({
                  title,
                  status,
                  min_salary,
                  max_salary,
                  created_at,
                  id,
                  active_vacancy,
                  vacancy_for_pooling,
                  interviews,
                }) => (
                  <JobList
                    key={id}
                    id={id}
                    title={title}
                    status={status}
                    min_salary={min_salary}
                    max_salary={max_salary}
                    created_at={created_at}
                    interviews={interviews}
                    active_vacancy={active_vacancy}
                    vacancy_for_pooling={vacancy_for_pooling}
                  />
                )
              )
            ) : (
              <EmptyList>No Job Found</EmptyList>
            )}
          </>
        ) : (
          <Loader />
        )}
      </StyledJobListingWrapper>

      <Pagination>
        <PageButtons
          totalPages={pagination?.pageCount}
          currentPage={pagination?.page}
          onPageClick={handleFetch}
          maxWidth={10}
        />

        <select value={pagination?.pageSize}>
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              View {pageSize}
            </option>
          ))}
        </select>
        <span>{`${pagination.page} - ${pagination?.pageSize} of ${pagination?.rowCount}`}</span>
      </Pagination>
    </Wrapper>
  )
}

export default JobListingWrapper

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const EmptyList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 300;
  color: #676e92;
`

const StyledJobListingWrapper = styled.div`
  height: calc(100vh - 17rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`
const FilterWrapper = styled.div`
  width: 100%;
  height: 38px;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 38px;
  gap: 0.75rem;

  .input-wrapper,
  .filterWrapper {
    width: 100%;
    height: 100%;
    background: #fff;
    border: 1px solid #dfdfe9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background: none;
      font-size: 0.75rem;
      font-weight: 300;
    }

    button {
      background: none;
      border: none;
    }

    .icon {
      font-size: 1rem;
      color: #31374f;
      cursor: pointer;
    }
  }

  .filterWrapper2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    background: #fff;
    border: 1px solid #dfdfe9;

    .icon {
      font-size: 1rem;
      color: #31374f;
    }
  }
`

// const Pagination = styled.div`
//   position: relative;
//   width: 100%;
//   background: #f9f9fc;
//   // position: relative;
//   bottom: 45px
//   display: flex;
//   align-items: center;

//   button {
//     height: 2rem;
//     width: 2rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 0.75rem;
//     border: 0.5px solid #aeb3c9;
//     background: #fff;
//     color: #aeb3c9;

//     &:hover,
//     &.active {
//       border: 0.5px solid #1877f2;
//     }

//     &:disabled {
//       background: #f3f3f8;
//       color: #aeb3c9;
//       border: 0.5px solid #aeb3c9;
//       cursor: not-allowed;
//     }
//   }

//   select {
//     height: 2rem;
//     background: #ffffff;
//     border: 0.5px solid #aeb3c9;
//     margin: 0 0.75rem;
//     font-size: 0.75rem;
//     padding: 0 0.5rem;
//     outline: none;
//   }

//   span {
//     font-size: 0.75rem;
//     font-weight: 300;
//     color: #0e1324;
//   }
// `

const Pagination = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  bottom: 15px;

  // button {
  //   height: 2rem;
  //   width: 2rem;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 0.75rem;
  //   border: 0.5px solid #aeb3c9;
  //   background: #fff;
  //   color: #aeb3c9;

  //   &:hover,
  //   &.active {
  //     border: 0.5px solid #1877f2;
  //   }

  //   &:disabled {
  //     background: #f3f3f8;
  //     color: #aeb3c9;
  //     border: 0.5px solid #aeb3c9;
  //     cursor: not-allowed;
  //   }
  // }

  select {
    height: 2rem;
    background: #ffffff;
    border: 0.5px solid #aeb3c9;
    margin: 0 0.75rem;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    outline: none;
  }

  span {
    white-space: nowrap;
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
  }
`
