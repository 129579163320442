/* eslint-disable camelcase */
import React, { useMemo } from 'react'

import moment from 'moment'

import { Tag } from '../../job-posting/components/ReviewPost'
import { StyledGrid, StyledJobDetails } from './JobDetail'

const Qualification = ({
  experience,
  software,
  technicalSkills,
  education,
  language,
  availability_to_start,
  certification,
  hardware_requirements,
  personal_skills,
  shift_availability,
}) => {
  const updatedExperience = useMemo(() => {
    const data = JSON.parse(experience)

    if (data instanceof Array) return data

    const convertedData = Object.values(data)

    return convertedData
  }, [experience])

  const updatedTechnicalSkills = useMemo(() => {
    if (!technicalSkills.includes('[{')) return []
    return JSON.parse(technicalSkills)
  }, [technicalSkills])

  const updatedLanguages = useMemo(() => {
    if (!language.includes('[{')) return []
    return JSON.parse(language)
  }, [language])

  const updatedHardware = useMemo(() => {
    if (!hardware_requirements || !hardware_requirements.includes('['))
      return []
    return JSON.parse(hardware_requirements)
  }, [hardware_requirements])

  const updatedPersonalSkills = useMemo(() => {
    if (!personal_skills || !personal_skills.includes('[')) return []
    return JSON.parse(personal_skills)
  }, [personal_skills])

  const updatedSoftwares = useMemo(() => {
    if (software === null) return []
    if (software.includes('}]')) {
      const result = []
      const data = JSON.parse(software)
      data.forEach((item) => {
        result.push(item.label)
      })

      return result
    }
    return software.split(',')
  }, [software])

  return (
    <StyledGrid>
      <StyledJobDetails>
        <div className="title">Experience</div>
        <div className="content">
          {updatedExperience.map(({ years, specification }) => (
            <p key={specification}>
              {years} - {specification}
            </p>
          ))}
        </div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Software</div>
        <div className="content">
          {updatedSoftwares.length &&
            updatedSoftwares.map((software) => (
              <p key={software}>{software}</p>
            ))}
        </div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Technical Skills</div>
        <div className="flex">
          {updatedTechnicalSkills.map(({ label }) => (
            <Tag key={label}>{label}</Tag>
          ))}
        </div>
      </StyledJobDetails>

      {language && (
        <StyledJobDetails>
          <div className="title">Languages</div>
          <div className="flex">
            {updatedLanguages.map(({ label }) => (
              <Tag key={label}>{label}</Tag>
            ))}
          </div>
        </StyledJobDetails>
      )}

      {education && (
        <StyledJobDetails>
          <div className="title">Education</div>
          <div className="content">{education}</div>
        </StyledJobDetails>
      )}

      {availability_to_start && (
        <StyledJobDetails>
          <div className="title">Available To Start</div>
          <div className="content">{moment(availability_to_start).format('Do MMM, YYYY')}</div>
        </StyledJobDetails>
      )}

      {certification && (
        <StyledJobDetails>
          <div className="title">Certification</div>
          <div className="content">{certification}</div>
        </StyledJobDetails>
      )}

      {shift_availability && (
        <StyledJobDetails>
          <div className="title">Shift Availability</div>
          <div className="content">{shift_availability}</div>
        </StyledJobDetails>
      )}

      {updatedHardware && (
        <StyledJobDetails>
          <div className="title">Hardware Requirement</div>
          <div className="flex">
            {updatedHardware.map((hardware) => (
              <Tag key={hardware}>{hardware}</Tag>
            ))}
          </div>
        </StyledJobDetails>
      )}

      {updatedPersonalSkills && (
        <StyledJobDetails>
          <div className="title">Personal Skills</div>
          <div className="flex">
            {updatedPersonalSkills.map(({ label }) => (
              <Tag key={label}>{label}</Tag>
            ))}
          </div>
        </StyledJobDetails>
      )}
    </StyledGrid>
  )
}

export default Qualification
