/* eslint-disable camelcase */
import React, { memo } from 'react'

import moment from 'moment'
import styled from 'styled-components'
import { FaSmile } from 'react-icons/fa'

import { P } from '../../../style/Typography.style'
import { sortByNewestFirst } from '../../../constant/sortBy'
import { useSelector } from 'react-redux'

const Notes = ({ data, loading }) => {
  const { activities } = useSelector((state) => state.modal)
  if (loading) return <P>Loading...</P>

  const parser = (note) => {
    try {
      return JSON.parse(note)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <StyledNotes activities={activities}>
      {data && data.resp.length > 0 ? (
        data.resp
          .sort(sortByNewestFirst)
          .map(({ created_at, id: noteID, note_author, notes }) => (
            <StyledNote key={noteID}>
              <FaSmile className="avatar" />

              <div className="details">
                <P size="0.75rem" weight="500">
                  {note_author}
                </P>
                <P size="0.75rem" weight="300">
                  {moment(created_at).format('MMMM Do, YYYY')} {' at '}
                  {moment(created_at).format('h:mm A')}
                </P>
                <P
                  marginTop="0.75rem"
                  size="0.75rem"
                  weight="300"
                  dangerouslySetInnerHTML={{ __html: parser(notes) }}
                />
              </div>
            </StyledNote>
          ))
      ) : (
        <P>No notes to display at the moment</P>
      )}
    </StyledNotes>
  )
}

export default memo(Notes)

export const StyledNotes = styled.section`
  width: 100%;
  padding: 0 0 1rem 0;
  min-height: 60px;

  .avatar {
    color: #ff7b9b;
    font-size: 36px;
  }
`

const StyledNote = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`
