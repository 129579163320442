/* eslint-disable camelcase */
import React from 'react'
import { useNavigate } from 'react-router'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'

import Flex from '../../../../components/layouts/Flex'
import { H2 } from '../../../../style/Typography.style'

import EditView from './EditView'
import PreviewPost from './PreviewPost'
import { FilterCheckBox } from '../../../../components/dashboard/FilterInput'
import DashboardButton from '../../../../components/dashboard/DashboardButton'
import SwitchToggle from '../../../../components/dashboard/SwitchToggle'

import createJobPost from '../../../../services/request/createJobPost'

import {
  clearJobPosting,
  setActiveJobId,
} from '../../../../store/actions/jobPostingActions'

const ReviewPost = () => {
  const [switchToggle, setSwitchToggle] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {
    title,
    description,
    company_id,
    poc_id,
    job_type,
    education,
    language,
    experience,
    certification,
    software,
    hardware_requirement,
    shift_availability,
    min_salary,
    max_salary,
    salary_basis,
    requires_coaching,
    active_vacancy,
    vacancy_for_pooling,
    time_shift,
    technical_skills,
    personal_skills,
    benefits,
    client,
    availability_to_start,
    recruiter_id,
  } = useSelector((state) => state.jobPosting)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const data = {
      title,
      description,
      company_id: JSON.stringify(company_id),
      poc_id: JSON.stringify(poc_id),
      job_type,
      language: JSON.stringify(language),
      experience: JSON.stringify(experience),
      hardware_requirement: JSON.stringify(hardware_requirement),
      min_salary,
      max_salary,
      salary_basis,
      requires_coaching,
      active_vacancy: active_vacancy.toString(),
      time_shift,
      technical_skills: JSON.stringify(technical_skills),
      personal_skills: JSON.stringify(personal_skills),
      benefits: JSON.stringify(benefits),
      client,
      recruiter_id: recruiter_id.toString(),
    }

    if (vacancy_for_pooling && vacancy_for_pooling.length > 0) {
      data.vacancy_for_pooling = vacancy_for_pooling.toString()
    }

    if (education && education !== '') {
      data.education = education
    }

    if (certification && certification !== '') {
      data.certification = certification
    }

    if (software && software.length > 0) {
      data.software = JSON.stringify(software)
    }

    if (shift_availability && shift_availability !== '') {
      data.shift_availability = shift_availability
    }

    if (availability_to_start && availability_to_start !== '') {
      data.availability_to_start = availability_to_start.toString()
    }

    createJobPost(data)
      .then((res) => {
        toast.success('Job Posting Created Successfully', { type: 'success' })
        setLoading(false)
        localStorage.setItem('jobTitle', res?.data?.data?.title)
        localStorage.removeItem('qualifications')
        localStorage.removeItem('skills')
        localStorage.removeItem('salaries')
        localStorage.removeItem('jobDetails')
        localStorage.removeItem('reachedStep')
        localStorage.removeItem('step')
        dispatch(clearJobPosting())
        dispatch(setActiveJobId(res?.data?.data?.id))
        navigate('/job-posting/publish')
      })
      .catch((err) => {
        toast.success(
          err.response.message ||
            err.response.data.message ||
            'Something went wrong, please try again.',
          { type: 'error' }
        )
        setLoading(false)
      })
  }

  return (
    <Flex
      margin="2.5rem 1rem 9rem 1rem"
      direction="column"
      width="100%"
      maxw="500px"
      maxwMobile="100%"
      as="form"
      onSubmit={handleSubmit}
    >
      <H2>Review Post</H2>

      {/* switch button */}
      <SwitchToggle
        active={switchToggle}
        setActive={() => setSwitchToggle(!switchToggle)}
        firstText="Edit View"
        secondText="Preview Post"
      />

      {/* display based on condition from the switch */}
      {switchToggle ? <EditView /> : <PreviewPost />}

      {/* save template */}
      <Flex
        direction="column"
        padding="1.5rem 0 0 0"
        margin="1.5rem 0 0 0"
        width="100%"
      >
        <FilterCheckBox label="Save Template" name="saveTemplate" />
      </Flex>

      {/*  submit button */}
      <Flex margin="1rem 0 0 0" width="100%" justify="flex-end">
        <DashboardButton
          text={loading ? 'Loading...' : 'Publish'}
          type="submit"
          rounded
          notIcon
        />
      </Flex>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Flex>
  )
}

export default ReviewPost

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    font-size: 1rem;
    color: #31374f;
  }
`

export const Tag = styled.div`
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: 100px;
  border: 0.5px solid #31374f;
  font-size: 0.75rem;
  color: #0e1324;
  font-weight: 400;
`
