/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { toast, ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import RichTextbox from '../../../../../components/dashboard/controls/RichTextBox'
import DashboardInput from '../../../../../components/dashboard/DashboardInput'
import { FilterCheckBox } from '../../../../../components/dashboard/FilterInput'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'
import { JobToggleButton } from '../../../../../components/dashboard/ToggleButton'
import Flex from '../../../../../components/layouts/Flex'
import ModalFooter from './ModalFooter'

import useFetch from '../../../../../hooks/useFetch'

import axiosInstance from '../../../../../services/config/AxiosIntance'

import { setRecruiterId } from '../../../../../store/actions/jobPostingActions'

import {
  SET_ACTIVE_VACANCY,
  SET_COMPANY_ID,
  SET_JOB_DESCRIPTION,
  SET_JOB_TITLE,
  SET_JOB_TYPE,
  SET_POLLING,
  SET_REQUIRES_COACHING,
  SET_TIME_SHIFT,
  SET_VACANCY_FOR_POOLING,
  SET_POC_ID,
  SET_RECRUITER_ID,
} from '../../../../../store/types/jobPosting'

import { jobs } from '../../../../../store/types/jobListing'

const JobDetailsUpdate = ({
  title: job_title,
  description: job_description,
  job_type: initial_job_type,
  id,
  active_vacancy: initial_active_vacancy,
  vacancy_for_pooling: initial_vacancy_for_pooling,
  time_shift: initial_time_shift,
  posted_by,
  client: initial_client,
  company_id: initial_company_id,
  point_of_contacts,
  requires_coaching: initial_requires_coaching,
  status,
  setOpenModal,
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [initialState, setIntialState] = useState({
    jobTitle: '',
    jobType: '',
    polling: false,
    activeVacancy: '',
    vacancyForPooling: '',
    timeShift: '',
    description: '',
    client: '',
    requiresCoaching: false,
    recruiters: [],
  })

  const {
    polling,
    requires_coaching,
    description,
    title,
    job_type,
    active_vacancy,
    vacancy_for_pooling,
    time_shift,
    client,
    recruiter_id,
    poc_id,
    company_id,
  } = useSelector((state) => state.jobPosting)

  const { data: recruiters } = useFetch('/recruiters')

  const [companies, setCompanies] = useState([])
  const [contacts, setContacts] = useState([])

  // handle pooling
  const handlePooling = () => {
    dispatch({ type: SET_POLLING })
    dispatch({
      type: SET_VACANCY_FOR_POOLING,
      payload: 0,
    })
  }

  const handleDescription = (value) => {
    dispatch({ type: SET_JOB_DESCRIPTION, payload: value })
  }

  useEffect(() => {
    setIntialState((prevState) => ({
      ...prevState,
      jobTitle: job_title,
      jobType: initial_job_type,
      polling: polling,
      activeVacancy: initial_active_vacancy,
      vacancyForPooling: initial_vacancy_for_pooling,
      timeShift: initial_time_shift,
      description: job_description,
      requiresCoaching: initial_requires_coaching,
      recruiter: posted_by,
      recruiter_id: recruiter_id,
      poc_id: poc_id,
    }))

    dispatch({ type: SET_JOB_TITLE, payload: job_title })
    dispatch({ type: SET_JOB_TYPE, payload: initial_job_type })
    dispatch({ type: SET_ACTIVE_VACANCY, payload: initial_active_vacancy })
    dispatch({
      type: SET_VACANCY_FOR_POOLING,
      payload: initial_vacancy_for_pooling,
    })
    dispatch({ type: SET_TIME_SHIFT, payload: initial_time_shift })
    dispatch({ type: SET_JOB_DESCRIPTION, payload: job_description })
    dispatch({
      type: SET_REQUIRES_COACHING,
      payload: initial_requires_coaching,
    })
    dispatch({ type: SET_RECRUITER_ID, payload: posted_by })
    dispatch({
      type: SET_COMPANY_ID,
      payload: JSON.stringify({ id: initial_company_id, name: initial_client }),
    })
    dispatch({
      type: SET_POC_ID,
      payload: JSON.stringify({
        id: point_of_contacts?.id,
        name: point_of_contacts?.name,
      }),
    })
  }, [job_title])

  useEffect(() => {
    axiosInstance.get('/company?client_status_id=1').then((response) => {
      setCompanies(response?.data?.data)
    })
  }, [])
  useEffect(() => {
    axiosInstance
      .get(`/poc?company_id=${company_id}&status_id=1`)
      .then((response) => {
        setContacts(response?.data?.data)
      })
  }, [company_id])

  const handleUpdate = async () => {
    if (title === '')
      return toast('Please enter a job title', { type: 'error' })
    if (description === '')
      return toast('Please enter a job summary', { type: 'error' })
    if (job_type === '')
      return toast('Please select a Employment type', { type: 'error' })
    if (active_vacancy === '')
      return toast('Please enter a active vacancy', { type: 'error' })
    if (client === '') return toast('Please select a client', { type: 'error' })
    if (time_shift === '')
      return toast('Please select a time shift', { type: 'error' })
    if (!poc_id)
      return toast('Please select a point of contact', { type: 'error' })
    if (recruiter_id && recruiter_id === '')
      return toast('Please select a recruiter', { type: 'error' })

    setLoading(true)

    await axiosInstance
      .put(`/${id}/job`, {
        title,
        description,
        job_type,
        active_vacancy: active_vacancy.toString(),
        vacancy_for_pooling: vacancy_for_pooling
          ? vacancy_for_pooling.toString()
          : '0',
        time_shift,
        client,
        requires_coaching,
        posted_by: recruiter_id.toString(),
        poc_id: poc_id.toString(),
        company_id: company_id.toString(),
      })
      .then((res) => {
        setLoading(false)
        toast(res?.data?.message || 'Job Updated Successfully', {
          type: 'success',
        })
        dispatch({
          type: jobs.SET_JOB_CHANGED,
        })
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message || 'Something went wrong.', {
          type: 'error',
        })
      })
  }

  const handleClientChange = (e) => {
    const client = companies.find(
      (client) => client.name.toString() === e.target.value
    )
    dispatch({
      type: SET_COMPANY_ID,
      payload: JSON.stringify(client),
    })
  }

  const handleContactChange = (e) => {
    const contact = contacts.find(
      (contact) => contact.id.toString() === e.target.value
    )
    dispatch({
      type: SET_POC_ID,
      payload: JSON.stringify(contact),
    })
  }

  function onClose() {
    dispatch({ type: SET_JOB_TITLE, payload: initialState.jobTitle })
    dispatch({ type: SET_JOB_TYPE, payload: initialState.jobType })
    dispatch({ type: SET_ACTIVE_VACANCY, payload: initialState.activeVacancy })
    dispatch({
      type: SET_VACANCY_FOR_POOLING,
      payload: initialState.vacancyForPooling,
    })
    dispatch({ type: SET_TIME_SHIFT, payload: initialState.timeShift })
    dispatch({ type: SET_JOB_DESCRIPTION, payload: initialState.description })
    setOpenModal(false)
  }

  return (
    <StyledJobDetailsUpdate>
      <div className="job-details">
        <DashboardInput
          label="Job Title"
          placeholder="e.g. Graphic Designer, Software Engineer"
          name="jobTitle"
          handleChange={(e) =>
            dispatch({ type: SET_JOB_TITLE, payload: e.target.value })
          }
          type="text"
          value={title}
          height="2.5rem"
          readOnly={status === 'closed'}
        />

        {/* employment type field needs options and state created */}
        <SingleDropdownSelect
          onChange={(e) =>
            dispatch({ type: SET_JOB_TYPE, payload: e.target.value })
          }
          value={job_type}
          title="Employment Type"
          disabled={status === 'closed'}
        >
          <option>Choose an option</option>
          <option value="full-time">Full time</option>
          <option value="part-time">Part time</option>
        </SingleDropdownSelect>

        {/* creating double type input box */}
        <Flex width="100%" mobileDirection="column">
          <FilterCheckBox
            checked={polling}
            disabled={status === 'closed'}
            size="small"
            pos="absolute"
            top="-.05rem"
            right="-1.75rem"
            label="For Pooling"
            name="ProfessionalHeadline"
            onChange={handlePooling}
          />
          <DashboardInput
            label="Active Vacancies"
            readOnly={status === 'closed'}
            placeholder={0}
            value={active_vacancy}
            handleChange={(e) =>
              dispatch({ type: SET_ACTIVE_VACANCY, payload: e.target.value })
            }
            name="activeVacancies"
            type="number"
            height="2.5rem"
          />
          {polling && (
            <DashboardInput
              label="Vacancy for Pooling"
              readOnly={status === 'closed'}
              placeholder={0}
              value={vacancy_for_pooling}
              handleChange={(e) =>
                dispatch({
                  type: SET_VACANCY_FOR_POOLING,
                  payload: e.target.value,
                })
              }
              name="vacancyForPooling"
              type="number"
              height="2.5rem"
            />
          )}
        </Flex>

        {/* employment type field needs options and state created */}
        <SingleDropdownSelect
          disabled={status === 'closed'}
          onChange={(e) =>
            dispatch({ type: SET_TIME_SHIFT, payload: e.target.value })
          }
          value={time_shift}
          title="Time Shift"
        >
          <option>Choose an option</option>
          <option value="Morning Shift - PH TIME">
            Morning Shift - PH TIME
          </option>
          <option value="Afternoon Shift - PH TIME">
            Afternoon Shift - PH TIME
          </option>
          <option value="Night Shift - PH TIME">Night Shift - PH TIME</option>
        </SingleDropdownSelect>

        {/*  */}
        <RichTextbox
          isReadOnly={status === 'closed'}
          label="Job Summary"
          content={description}
          setContent={handleDescription}
        />

        <SingleDropdownSelect
          disabled={status === 'closed'}
          onChange={(e) => dispatch(setRecruiterId(e.target.value))}
          title="Recruiter"
          value={recruiter_id}
        >
          <option>Choose an option</option>
          {recruiters?.resp?.map((recruiter) => {
            if (recruiter?.Recruiter?.name) {
              return (
                <option
                  key={recruiter?.Recruiter?.user_id}
                  value={recruiter?.Recruiter?.user_id}
                >
                  {recruiter?.Recruiter?.name}
                </option>
              )
            }
            return null
          })}
        </SingleDropdownSelect>

        {/* employment type field needs options and state created */}
        <SingleDropdownSelect
          onChange={handleClientChange}
          title="Client"
          value={client}
        >
          <option>Choose an option</option>
          {companies?.map((item) => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </SingleDropdownSelect>

        <SingleDropdownSelect
          onChange={handleContactChange}
          title="Point of Contact"
          value={poc_id}
        >
          <option>Choose an option</option>
          {contacts?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </SingleDropdownSelect>

        {/* Toggle button */}
        <JobToggleButton
          value={requires_coaching}
          onClick={(e) => {
            if (status === 'closed') {
              e.preventDefault()
            } else {
              dispatch({ type: SET_REQUIRES_COACHING })
            }
          }}
          label="This listing requires coaching"
          mt=".875rem"
          mb="1.5rem"
        />
      </div>

      <ModalFooter
        onClose={onClose}
        loading={loading}
        readOnly={status === 'closed'}
        handleUpdate={handleUpdate}
      />

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </StyledJobDetailsUpdate>
  )
}

export default JobDetailsUpdate

const StyledJobDetailsUpdate = styled.div`
  width: 100%;
  height: 100%;

  .job-details {
    max-height: 65vh;
    overflow-y: auto;
    padding-right: 0.5rem;
  }
`
