import React from 'react'

import styled from 'styled-components'
import { P } from '../../style/Typography.style'
import DefaultTemplate from './template/DefaultTemplate'

const Preview = ({ downloadRef }) => {
  return (
    <StyledPreview>
      <P size=".875rem" color="#31374F">
        Preview
      </P>

      <DefaultTemplate downloadRef={downloadRef} />
    </StyledPreview>
  )
}

export default Preview

const StyledPreview = styled.div`
  width: 100%;
`
