import requisitionType from '../types/requisition'

export const setModal = (modalType) => {
  return { type: requisitionType.SETMODAL, payload: modalType }
}

export const setInterviews = (interviews) => {
  return { type: requisitionType.SETINTERVIEWS, payload: interviews }
}

export const setSearch = (search) => {
  return { type: requisitionType.SETSEARCH, payload: search }
}

export const setSearchRole = (searchRole) => {
  return { type: requisitionType.SETSEARCHROLE, payload: searchRole }
}

export const setUpdatedStage = (updatedStage) => {
  return { type: requisitionType.SETUPDATEDSTAGE, payload: updatedStage }
}

export const setSelectedInterview = (interview) => {
  return { type: requisitionType.SETSELECTEDINTERVIEW, payload: interview }
}

export const setRecruiters = (recruiters) => {
  return { type: requisitionType.SETRECRUITERS, payload: recruiters }
}

export const setInterviewStage = (stage) => {
  return { type: requisitionType.SETSTAGE, payload: stage }
}

export const setModalFooter = (footer) => {
  return { type: requisitionType.SETMODALFOOTER, payload: footer }
}

export const setFilters = (filter) => {
  return { type: requisitionType.SETFILTERS, payload: filter }
}

export const clearFilters = () => ({
  type: requisitionType.CLEARFILTERS,
})
