import React, { useMemo } from 'react'
import { Tag } from '../../job-posting/components/ReviewPost'
import { StyledGrid, StyledJobDetails } from './JobDetail'

const SalaryAndBenefits = ({ minSalary, maxSalary, basis, benefits }) => {
  const updatedBenefits = useMemo(() => {
    if (!benefits.includes('["')) return []
    return JSON.parse(benefits)
  }, [benefits])
  
  return (
    <StyledGrid>
      <StyledJobDetails>
        <div className="title">Payment Range</div>
        <div className="content">
          PHP {parseFloat(minSalary).toLocaleString()} - PHP {parseFloat(maxSalary).toLocaleString()}
        </div>
      </StyledJobDetails>
      <StyledJobDetails>
        <div className="title">Basic</div>
        <div className="content">{basis}</div>
      </StyledJobDetails>
      {
        updatedBenefits.length > 0 && (
          <StyledJobDetails>
            <div className="title">Benefits</div>
            <div className="flex">
              {updatedBenefits.map((item) => (
                <Tag key={item}>{item.toString()}</Tag>
              ))}
            </div>
          </StyledJobDetails>
        )
      }
    </StyledGrid>
  )
}

export default SalaryAndBenefits
