import {
  SWITCH_STEP,
  SET_POLLING,
  SET_REACH_STEP,
  SET_IS_EDUCATION,
  SET_IS_LANGUAGE,
  SET_IS_SHIFT,
  SET_IS_EXPERIENCE,
  SET_IS_CERTIFICATION,
  SET_IS_SOFTWARE,
  SET_IS_HARDWARE,
  SET_IS_PERSONAL_SKILLS,
  SET_IS_SKILL,
  SET_JOB_TITLE,
  SET_JOB_DESCRIPTION,
  SET_JOB_TYPE,
  SET_EDUCATION,
  SET_LANGUAGE,
  SET_EXPERIENCE,
  SET_CERTIFICATION,
  SET_CLOSING_DATE,
  SET_TIME_SHIFT,
  SET_ACTIVE_VACANCY,
  SET_VACANCY_FOR_POOLING,
  SET_TECHNICAL_SKILLS,
  SET_REQUIRES_COACHING,
  SET_SOFTWARE,
  SET_HARDWARE_REQUIREMENT,
  SET_SHIFT_AVAILABILITY,
  SET_COMPANY_ID,
  SET_POC_ID,
  SET_PERSONAL_SKILLS,
  SET_MIN_SALARY,
  SET_MAX_SALARY,
  SET_BENEFITS,
  SET_SALARY_BASIS,
  CLEAR_JOB_POSTING,
  SET_IS_AVAILABLE_TO_START,
  SET_AVAILABLE_TO_START_DATE,
  SET_RECRUITER_ID,
  SET_UPDATE_STATUS_TAB,
  SET_ACTIVE_JOB_ID,
} from '../types/jobPosting'

const jobDetails = JSON.parse(localStorage.getItem('jobDetails'))
const salaries = JSON.parse(localStorage.getItem('salaries'))
const qualifications = JSON.parse(localStorage.getItem('qualifications'))

const intialState = {
  step: parseInt(localStorage.getItem('step') || 1),
  reachedStep: parseInt(localStorage.getItem('reachedStep') || 1),
  polling: (jobDetails && jobDetails.polling) || true,
  isEducation: (qualifications && qualifications.isEducation) || false,
  isLanguage: (qualifications && qualifications.isLanguage) || false,
  isShift: (qualifications && qualifications.isShift) || false,
  isExperience: (qualifications && qualifications.isExperience) || false,
  isCertification: (qualifications && qualifications.isCertification) || false,
  isSoftware: (qualifications && qualifications.isSoftware) || false,
  isHardware: (qualifications && qualifications.isHardware) || false,
  isSkill: (qualifications && qualifications.isSkill) || false,
  isPersonalSkills:
    (qualifications && qualifications.isPersonalSkills) || false,
  isAvalableToStart: (jobDetails && jobDetails.available_to_start) || false,
  // job posting data
  recruiter_id: (jobDetails && jobDetails.recruiter_id) || '',
  company_id: (jobDetails && jobDetails.company_id) || '',
  client: (jobDetails && jobDetails.client) || '',
  poc_id: (jobDetails && jobDetails.poc_id) || '',
  pointOfContact: (jobDetails && jobDetails.pointOfContact) || '',
  title: (jobDetails && jobDetails.title) || '',
  description: (jobDetails && jobDetails.description) || '',
  job_type: (jobDetails && jobDetails.job_type) || '',
  education: (qualifications && qualifications.education) || '',
  language: (qualifications && qualifications.language) || [],
  experience: (qualifications && qualifications.experience) || [
    {
      years: '',
      specification: '',
    },
    {
      years: '',
      specification: '',
    },
  ],
  closing_date: '',
  time_shift: (jobDetails && jobDetails.time_shift) || '',
  active_vacancy: (jobDetails && jobDetails.active_vacancy) || 0,
  vacancy_for_pooling: (jobDetails && jobDetails.vacancy_for_pooling) || 0,
  requires_coaching: (jobDetails && jobDetails.requires_coaching) || false,
  certification: (qualifications && qualifications.certification) || '',
  technical_skills: (qualifications && qualifications.technical_skills) || [],
  personal_skills: (qualifications && qualifications.personal_skills) || [],
  software: (qualifications && qualifications.software) || '',
  hardware_requirement: (qualifications &&
    qualifications.hardware_requirement) || [
    'Minimum 30 mbps internet connectivity',
    'Backup 15 mbps internet connectivity',
    'At least Intel Core i-5 (or AMD equivalent) with 8GB RAM',
  ],
  shift_availability:
    (qualifications && qualifications.shift_availability) || '',
  min_salary: (salaries && salaries.min_salary) || 0,
  max_salary: (salaries && salaries.max_salary) || 0,
  benefits: (salaries && salaries.benefits) || [],
  salary_basis: (salaries && salaries.salary_basis) || 'Per Month',
  availability_to_start:
    (jobDetails && jobDetails.availability_to_start) || null,
  updateStatusTab: 'Job Details',
  jobId: '',
}

const jobPostingReducer = (state = intialState, action) => {
  switch (action.type) {
    case SWITCH_STEP:
      return {
        ...state,
        step: action.payload,
      }

    case SET_REACH_STEP:
      return {
        ...state,
        reachedStep: action.payload,
      }

    case SET_POLLING:
      return {
        ...state,
        polling: !state.polling,
      }

    case SET_IS_EDUCATION:
      return {
        ...state,
        isEducation: !state.isEducation,
      }

    case SET_IS_PERSONAL_SKILLS:
      return {
        ...state,
        isPersonalSkills: !state.isPersonalSkills,
      }

    case SET_IS_LANGUAGE:
      return {
        ...state,
        isLanguage: !state.isLanguage,
      }

    case SET_IS_SHIFT:
      return {
        ...state,
        isShift: !state.isShift,
      }

    case SET_IS_EXPERIENCE:
      return {
        ...state,
        isExperience: !state.isExperience,
      }

    case SET_IS_CERTIFICATION:
      return {
        ...state,
        isCertification: !state.isCertification,
      }

    case SET_IS_SOFTWARE:
      return {
        ...state,
        isSoftware: !state.isSoftware,
      }

    case SET_IS_HARDWARE:
      return {
        ...state,
        isHardware: !state.isHardware,
      }

    case SET_IS_SKILL:
      return {
        ...state,
        isSkill: !state.isSkill,
      }

    case SET_IS_AVAILABLE_TO_START:
      return {
        ...state,
        isAvalableToStart: action.payload,
      }

    // input data update
    case SET_JOB_TITLE:
      return {
        ...state,
        title: action.payload,
      }

    case SET_JOB_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      }

    case SET_JOB_TYPE:
      return {
        ...state,
        job_type: action.payload,
      }

    case SET_RECRUITER_ID:
      return {
        ...state,
        recruiter_id: action.payload,
      }

    case SET_EDUCATION:
      return {
        ...state,
        education: action.payload,
      }

    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      }

    case SET_EXPERIENCE:
      return {
        ...state,
        experience: action.payload,
      }

    case SET_CERTIFICATION:
      return {
        ...state,
        certification: action.payload,
      }

    case SET_CLOSING_DATE:
      return {
        ...state,
        closing_date: action.payload,
      }

    case SET_TIME_SHIFT:
      return {
        ...state,
        time_shift: action.payload,
      }

    case SET_ACTIVE_VACANCY:
      return {
        ...state,
        active_vacancy: action.payload,
      }

    case SET_VACANCY_FOR_POOLING:
      return {
        ...state,
        vacancy_for_pooling: action.payload,
      }

    case SET_REQUIRES_COACHING: {
      if (action.payload || action.payload === false) {
        return {
          ...state,
          requires_coaching: action.payload,
        }
      } else {
        return {
          ...state,
          requires_coaching: !state.requires_coaching,
        }
      }
    }

    case SET_TECHNICAL_SKILLS:
      return {
        ...state,
        technical_skills: action.payload,
      }

    case SET_SOFTWARE:
      return {
        ...state,
        software: action.payload,
      }

    case SET_PERSONAL_SKILLS:
      return {
        ...state,
        personal_skills: action.payload,
      }

    case SET_HARDWARE_REQUIREMENT:
      return {
        ...state,
        hardware_requirement: action.payload,
      }

    case SET_SHIFT_AVAILABILITY:
      return {
        ...state,
        shift_availability: action.payload,
      }

    case SET_COMPANY_ID:
      return {
        ...state,
        company_id: JSON.parse(action.payload).id,
        client: JSON.parse(action.payload).name,
        poc_id: null,
        pointOfContact: '',
      }
    case SET_POC_ID:
      return {
        ...state,
        poc_id: JSON.parse(action.payload).id,
        pointOfContact: JSON.parse(action.payload).name,
      }

    case SET_MIN_SALARY:
      return {
        ...state,
        min_salary: action.payload,
      }

    case SET_MAX_SALARY:
      return {
        ...state,
        max_salary: action.payload,
      }

    case SET_BENEFITS:
      return {
        ...state,
        benefits: action.payload,
      }

    case SET_SALARY_BASIS:
      return {
        ...state,
        salary_basis: action.payload,
      }

    case SET_AVAILABLE_TO_START_DATE:
      return {
        ...state,
        availability_to_start: action.payload,
      }

    case SET_UPDATE_STATUS_TAB:
      return {
        ...state,
        updateStatusTab: action.payload,
      }

    case CLEAR_JOB_POSTING:
      return intialState

    case SET_ACTIVE_JOB_ID:
      return {
        ...state,
        jobId: action.payload,
      }

    default:
      return state
  }
}

export default jobPostingReducer
