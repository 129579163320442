import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import styled from 'styled-components'
import moment from 'moment'
import axios from 'axios'

import { H2, P } from '../../../style/Typography.style'

import CVImage from '../../dashboard/TalentPool/CVImage'

import distributedLogo from '../../../assets/image/distributedLogo.png'

import { getCVImage } from '../../../utils/utils'

import { baseUrl } from '../../../constant/constant'

const CVTemplate = ({
  downloadRef,
  talent,
  cvPhoto,
  wfh,
  remoteWorkEthics,
  showLastName,
  contactDetails,
  charRef,
}) => {
  const [workFromHome, setWorkFromHome] = useState({
    compSpec: '',
    pheripheral: '',
    remoteWork: '',
    internetSpeedTest: '',
  })

  const [headLines, SetHeadlines] = useState([])

  const { uploadedCv } = useSelector((state) => state.talents)

  const meta = talent && talent && talent.meta ? JSON.parse(talent.meta) : {}
  const educationalData = meta && meta.educationalData
  const experienceState = meta && meta.experienceState
  const professionalSummary =
    talent &&
    talent.professional_summary &&
    JSON.parse(talent.professional_summary)

  const remoteWorkEthicsMeta =
    talent?.work_from_home_meta && JSON.parse(talent?.work_from_home_meta)

  const characterReference =
    talent && talent.character_reference
      ? JSON.parse(talent.character_reference)
      : []

  useEffect(() => {
    const list = []

    const proHeadline =
      talent &&
      talent.professional_headline &&
      JSON.parse(talent.professional_headline)

    proHeadline && proHeadline.map((headline) => list.push(headline.item))

    SetHeadlines(list.splice(',').join(' • '))

    axios
      .get(`${baseUrl}/user/${talent.user_id}/remote-work`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('recruiterToken')}`,
        },
      })
      .then((res) => {
        const computerSpec =
          res.data.resp && res.data.resp.computer_specification
            ? JSON.parse(res.data.resp.computer_specification)
            : ''
        const pheripheral =
          res?.data?.resp?.peripherals &&
          JSON.parse(res?.data?.resp?.peripherals)
        const remoteWork = res?.data?.resp && JSON.parse(res?.data?.resp?.meta)
        const internetSpeedTest =
          res?.data?.resp && JSON.parse(res?.data?.resp?.internet_speed_test)

        setWorkFromHome({
          compSpec: computerSpec,
          pheripheral: pheripheral,
          remoteWork: remoteWork,
          internetSpeedTest: internetSpeedTest,
        })
      })
  }, [talent])

  const getApplicantName = () => {
    let applicantName = ''
    if (talent?.first_name) {
      if (showLastName) {
        if (talent?.middle_name) {
          applicantName = `${talent?.first_name} ${talent?.middle_name} ${talent?.last_name}`
        } else {
          applicantName = `${talent?.first_name} ${talent?.last_name}`
        }
      } else {
        if (talent?.middle_name) {
          applicantName = `${talent?.first_name} ${talent?.middle_name}`
        } else {
          applicantName = `${talent?.first_name}`
        }
      }
    } else {
      applicantName = talent?.name
    }
    return applicantName
  }

  return (
    <StyledTemplateOne ref={downloadRef}>
      {uploadedCv && uploadedCv.public_id ? (
        <>
          {typeof uploadedCv === 'object' &&
            [...Array(uploadedCv?.pages).keys()].map((page) => (
              <CVImage
                key={page}
                url={uploadedCv?.url?.slice(0, -3) + 'jpg'}
                pageNumber={page + 1}
              />
            ))}
        </>
      ) : null}
      {uploadedCv.length <= 0 && (
        <>
          <div className="cv__header">
            <div className="info">
              {cvPhoto && (
                <div className="avatar">
                  {talent && getCVImage(talent) && (
                    <img src={getCVImage(talent)} alt="avatar" />
                  )}
                </div>
              )}
              <div>
                <H2
                  mb=".5rem"
                  align="left"
                  textTransform="uppercase"
                  size="2rem"
                  weight="700"
                >
                  {getApplicantName()}
                </H2>
                <P>{headLines && headLines}</P>
                <div className="contact">
                  {contactDetails && (
                    <>
                      <P>{talent && talent?.phone_number}</P>
                      <P>{talent && talent.email}</P>
                    </>
                  )}
                  <P>
                    {meta && meta.streetAddress ? meta.streetAddress : ''},{' '}
                    {talent && talent.city ? talent.city : ''},{' '}
                    {talent && talent.country ? talent.country : ''}
                  </P>
                </div>
              </div>
            </div>
            {/* logo */}
            <img
              className="company__logo"
              src={distributedLogo}
              alt="distributed"
            />
          </div>

          <div className="cv__body">
            <div className="cv__body--left">
              <P
                textTransform="uppercase"
                mb="1rem"
                weight="500"
                size=".875rem"
              >
                Technical Skills
              </P>
              <div className="skills">
                {meta &&
                  meta.fullSkills &&
                  meta.fullSkills[0] &&
                  meta.fullSkills[0].length > 0 &&
                  meta.fullSkills[0].map(({ skill }, index) => (
                    <div key={index} className="skill">
                      {skill}
                    </div>
                  ))}
              </div>

              <P
                textTransform="uppercase"
                mb="1rem"
                weight="500"
                size=".875rem"
              >
                Personal Skills
              </P>

              <div className="skills">
                {meta &&
                  meta.fullSkills &&
                  meta.fullSkills[1] &&
                  meta.fullSkills[1].length > 0 &&
                  meta.fullSkills[1].map(({ value }, index) => (
                    <div key={index} className="skill">
                      {value}
                    </div>
                  ))}
              </div>
            </div>
            <div className="cv__body--right">
              <>
                <div className="right__section--wrapper">
                  <P
                    textTransform="uppercase"
                    mb="1rem"
                    weight="500"
                    size=".875rem"
                  >
                    Professional Summary
                  </P>

                  <P
                    weight="300"
                    size=".875rem"
                    dangerouslySetInnerHTML={{
                      __html: professionalSummary,
                    }}
                  />
                </div>

                <div className="right__section--wrapper">
                  <P
                    textTransform="uppercase"
                    mt="1.5rem"
                    mb="1rem"
                    weight="500"
                    size=".875rem"
                  >
                    Work Experience
                  </P>

                  {experienceState && experienceState.length > 0 ? (
                    experienceState.map(
                      (
                        {
                          companyName,
                          jobTitle,
                          jobDescriptionHtml,
                          experienceFrom,
                          experienceTo,
                          experienceCurrentStatus,
                          workType,
                        },
                        index
                      ) => (
                        <div className="right__section--two-grid" key={index}>
                          <div>
                            <P weight="500" size=".75rem">
                              {moment(experienceFrom).format('MMM YYYY')} -{' '}
                              {experienceCurrentStatus === 'Currently employed'
                                ? 'Present'
                                : moment(experienceTo).format('MMM YYYY')}
                            </P>
                            <P weight="300" size=".75rem">
                              {companyName}
                            </P>
                            <P weight="300" size=".75rem">
                              {workType}
                            </P>
                          </div>
                          <div>
                            <P weight="500" size=".75rem">
                              {jobTitle}
                            </P>
                            <P
                              weight="300"
                              size=".75rem"
                              dangerouslySetInnerHTML={{
                                __html: jobDescriptionHtml,
                              }}
                            />
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <P>No experience</P>
                  )}
                </div>

                <div className="right__section--wrapper">
                  <P
                    textTransform="uppercase"
                    mt="1.5rem"
                    mb="1rem"
                    weight="500"
                    size=".875rem"
                  >
                    Education
                  </P>

                  {educationalData && educationalData.length > 0 ? (
                    educationalData.map(
                      ({ fieldOfStudy, school, from, to }, index) => (
                        <>
                          <div className="right__section--two-grid">
                            <div>
                              <P weight="500" size=".75rem">
                                {moment(from).format('MMM YYYY')} -{' '}
                                {to === ''
                                  ? 'Present'
                                  : moment(to).format('MMM YYYY')}
                              </P>
                            </div>
                            <div>
                              <P weight="500" size=".75rem">
                                {fieldOfStudy}
                              </P>
                              <P weight="300" size=".75rem">
                                {school}
                              </P>
                            </div>
                          </div>
                        </>
                      )
                    )
                  ) : (
                    <P>No education</P>
                  )}
                </div>
              </>
            </div>
          </div>
          <div className="cv__body--full">
            {workFromHome && wfh && (
              <div className="full__section--wrapper">
                <P
                  textTransform="uppercase"
                  padding="1rem 0 0 1.5rem"
                  weight="500"
                  size=".875rem"
                >
                  WFH Setup
                </P>
                <div className="wfh__section">
                  <div>
                    <P weight="500" size=".75rem">
                      Download Speed (MBPS)
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.internetSpeedTest?.downloadSpeed
                        ? workFromHome?.internetSpeedTest?.downloadSpeed
                        : 'not specified'}
                    </P>
                  </div>
                  <div>
                    <P weight="500" size=".75rem">
                      Upload Speed (MBPS)
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.internetSpeedTest?.uploadSpeed
                        ? workFromHome?.internetSpeedTest?.uploadSpeed
                        : 'not specified'}
                    </P>
                  </div>
                  <div>
                    <P weight="500" size=".75rem">
                      Ping (MS)
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.internetSpeedTest?.pingTime
                        ? workFromHome?.internetSpeedTest?.pingTime
                        : 'not specified'}
                    </P>
                  </div>
                  <div>
                    <P weight="500" size=".75rem">
                      Operating System
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.compSpec?.os
                        ? workFromHome?.compSpec?.os
                        : 'not specified'}
                    </P>
                  </div>
                  <div>
                    <P weight="500" size=".75rem">
                      OS Version
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.compSpec?.osVersion
                        ? workFromHome?.compSpec?.osVersion
                        : 'not specified'}
                    </P>
                  </div>
                  <div>
                    <P weight="500" size=".75rem">
                      Installed RAM (GB)
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.compSpec?.ram
                        ? workFromHome?.compSpec?.ram
                        : 'not specified'}
                    </P>
                  </div>
                  <div>
                    <P weight="500" size=".75rem">
                      WebCam
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.pheripheral?.webCamera
                        ? workFromHome?.pheripheral?.webCamera
                        : 'not specified'}
                    </P>
                  </div>
                  <div>
                    <P weight="500" size=".75rem">
                      Headset
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.pheripheral?.headSet
                        ? workFromHome?.pheripheral?.headSet
                        : 'not specified'}
                    </P>
                  </div>
                  <div>
                    <P weight="500" size=".75rem">
                      Microphone
                    </P>
                    <P weight="300" size=".75rem">
                      {workFromHome?.pheripheral?.microphone
                        ? workFromHome?.pheripheral?.microphone
                        : 'not specified'}
                    </P>
                  </div>
                </div>
              </div>
            )}
            {remoteWorkEthics && (
              <div className="full__section--wrapper">
                <P
                  textTransform="uppercase"
                  padding="1rem 0 0 1.5rem"
                  weight="500"
                  size=".875rem"
                >
                  Remote Fitness Level
                </P>
                <div className="remote__fitness__section">
                  <div>
                    <P weight="500" size=".75rem">
                      Work Area
                    </P>
                    <P weight="500" size=".75rem">
                      Device
                    </P>
                    <P weight="500" size=".75rem">
                      Schedule
                    </P>
                    <P weight="500" size=".75rem">
                      Shift
                    </P>
                    <P weight="500" size=".75rem">
                      Time Tracker
                    </P>
                  </div>

                  <div>
                    <P weight="300" size=".75rem">
                      {remoteWorkEthicsMeta?.workStation}
                    </P>
                    <P weight="300" size=".75rem">
                      {remoteWorkEthicsMeta?.device}
                    </P>
                    <P weight="300" size=".75rem">
                      {remoteWorkEthicsMeta?.workSchedule}
                    </P>
                    <P weight="300" size=".75rem">
                      {remoteWorkEthicsMeta?.workShift}
                    </P>
                    <P weight="300" size=".75rem">
                      {remoteWorkEthicsMeta?.timeTracker}
                    </P>
                  </div>
                </div>
              </div>
            )}
            {charRef && (
              <div className="full__section--wrapper">
                <P
                  textTransform="uppercase"
                  padding="1rem 0 0 1.5rem"
                  weight="500"
                  size=".875rem"
                >
                  Character Reference
                </P>
                <div className="wfh__section">
                  {characterReference && characterReference.length > 0 ? (
                    characterReference.map(
                      ({ id, crName, crEmail, crPhone }, index) => (
                        <div key={id}>
                          <P weight="500" size=".75rem">
                            {crName}
                          </P>
                          <P weight="300" size=".75rem">
                            {crEmail}
                          </P>
                          <P weight="300" size=".75rem">
                            {crPhone}
                          </P>
                        </div>
                      )
                    )
                  ) : (
                    <P>No Character Reference</P>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </StyledTemplateOne>
  )
}

const mapStateToProps = (state) => ({
  talent: state.talents.talent,
  cvPhoto: state.modal.cvPhoto,
  wfh: state.modal.wfh,
  remoteWorkEthics: state.modal.remoteWorkEthics,
  showLastName: state.modal.showLastName,
  contactDetails: state.modal.contactDetails,
  charRef: state.modal.charRef,
})

export default connect(mapStateToProps, {})(CVTemplate)

const StyledTemplateOne = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  border: 1px solid #aeb3c9;
  border-radius: 3px;

  .company__logo {
    height: 3rem;
    width: auto;
  }

  .contact {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-top: 1rem;
  }

  .cv__header {
    width: 100%;
    padding: 1.5rem;
    border-bottom: 1px solid #aeb3c9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
    }

    .avatar {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin-right: 1rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .cv__body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #aeb3c9;

    .cv__body--left {
      width: 100%;
      max-width: 300px;
      min-height: 21rem;
      padding: 1rem 1.5rem;

      .skills {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin: 1rem 0 1.5rem 0;

        .skill {
          font-size: 0.75rem;
          padding: 0.5rem;
          font-weight: 300;
          border: 1px solid #0e1324;
          color: #0e1324;
          border-radius: 100px;
        }
      }
    }

    .cv__body--right {
      border-left: 1px solid #aeb3c9;
      width: 100%;

      .right__section--wrapper {
        padding: 2.5rem 3.5rem;

        :not(:last-child) {
          border-bottom: 1px solid #aeb3c9;
        }
      }

      .right__section--two-grid {
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-gap: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .wfh__section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  .cv__body--full {
    .full__section--wrapper {
      width: 100%;
    }

    .wfh__section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.5rem;
      padding: 1rem 1.5rem;
      border-bottom: 1px solid rgb(174, 179, 201);
    }

    .remote__fitness__section {
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-gap: 0.5rem;
      padding: 1rem 1.5rem;
      border-bottom: 1px solid rgb(174, 179, 201);
    }

    .charref__section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.5rem;
      padding: 1rem 1.5rem;
      border-bottom: 1px solid rgb(174, 179, 201);
    }
  }
`
